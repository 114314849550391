<!--收银交易-->
<template>
    <div class="Cash">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="流水号">
                            <el-input placeholder="交易流水号" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商品名称">
                            <el-input placeholder="商品名称" v-model="form.goodsSearch" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="收银员/业务员" v-model="form.nameSearch" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员">
                            <el-input placeholder="会员姓名/手机号" v-model="form.memberSearch" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="推荐人">
                            <el-input placeholder="推荐人姓名" v-model="form.developMemberName" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="状态">
                            <el-select placeholder="请选择" v-model="form.settleStatus">
                                <el-option label="请选择" value="" />
                                <el-option label="待结算" value="0" />
                                <el-option label="已结算" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <!--                        <ef-start-date v-model="form.startTime" />-->
                        <!--                        :picker-options="pickerOptions"-->
                        <el-form-item label="交易日期">
                            <el-date-picker
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                placeholder="选择日期"
                                v-model="timeAll"
                                value-format="yyyy-MM-dd"
                                :editable="false"
                                :picker-options="pickerOptions"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <!--                        <ef-end-date v-model="form.endTime" />-->
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="searchHandleQuery" size="small" v-if="hasPrivilege('menu.sale.cash.open')"
                >查询
            </el-button>
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.sale.cash.export')">
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                ref="table"
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="机构名称" width="80" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="交易流水号" width="80" prop="tradeBar" v-if="showColumn('tradeBar')" />
                <el-table-column label="交易时间" width="120" prop="tradeTime" v-if="showColumn('tradeTime')" />
                <el-table-column label="收银员" width="80" prop="cashier" v-if="showColumn('cashier')" />
                <el-table-column label="交易类型" width="80" prop="tradeTypeName" v-if="showColumn('tradeTypeName')" />
                <el-table-column label="业务员" width="80" prop="salesmanName" v-if="showColumn('salesmanName')" />
                <el-table-column
                    label="状态"
                    width="80"
                    prop="settleStatus"
                    v-if="showColumn('settleStatus')"
                    key="settleStatus"
                >
                    <template slot-scope="scope"
                        ><span>{{ scope.row.settleStatus | settleStatus }}</span></template
                    >
                </el-table-column>
                <el-table-column
                    label="原价"
                    width="80"
                    prop="originalPrice"
                    v-if="showColumn('originalPrice')"
                    key="originalPrice"
                >
                    <template slot-scope="scope"
                        ><span>{{ scope.row.originalPrice | money }}</span></template
                    >
                </el-table-column>
                <el-table-column
                    label="优惠总额"
                    width="80"
                    prop="totalFavour"
                    v-if="showColumn('totalFavour')"
                    key="totalFavour"
                >
                    <template slot-scope="scope"
                        ><span>{{ caclTradeFavour(scope.row) | money }}</span></template
                    >
                </el-table-column>
                <el-table-column label="抹零" width="80" prop="floor" v-if="showColumn('floor')" key="floor">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.floor | money }}</span></template
                    >
                </el-table-column>
                <el-table-column label="实收" width="80" prop="paid" v-if="showColumn('paid')" key="paid">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.paid | money }}</span></template
                    >
                </el-table-column>
                <el-table-column label="会员姓名" width="100" prop="memberName" v-if="showColumn('memberName')" />
                <el-table-column label="会员电话" width="100" prop="memberMobile" v-if="showColumn('memberMobile')" />
                <el-table-column label="会员折扣" width="100" prop="memberDiscount" v-if="showColumn('memberDiscount')">
                    <template slot-scope="scope">
                        <div>
                            <div v-if="scope.row.memberDiscount">
                                {{ scope.row.memberDiscount }}
                                <span v-if="scope.row.memberDiscount != scope.row.originalMemberDiscount"
                                    >（原{{ scope.row.originalMemberDiscount }}）</span
                                >
                            </div>
                            <div v-else>{{ scope.row.originalMemberDiscount }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="推荐人"
                    width="100"
                    prop="developMemberName"
                    v-if="showColumn('developMemberName')"
                />
                <el-table-column
                    label="操作"
                    header-align="center"
                    v-if="showColumn('operate')"
                    key="operate"
                    prop="operate"
                >
                    <template slot-scope="scope">
                        <el-button size="mini" @click="rowDetail(scope.row)" v-if="hasPrivilege('menu.sale.cash.open')">
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import UrlUtils from 'js/UrlUtils';
import { DeptTypeEnum } from 'js/DeptCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Cash',
    mixins: [CheckTableShowColumn],
    components: { CheckTableShowColumnDialog },
    data() {
        return {
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.minDate = minDate;
                    this.maxDate = maxDate;
                },
                disabledDate: (time) => {
                    if (this.minDate && !this.maxDate) {
                        const range = 90 * 24 * 3600 * 1000; // 要更改禁用的日期范围只要修改这里的代码就可以
                        const minTime = this.minDate.getTime();
                        const newTime = time.getTime();
                        return newTime > minTime + range || newTime < minTime - range;
                    }
                    return false;
                },
            },
            minDate: '',
            maxDate: '',
            timeAll: [this.defaultStartTime(), this.defaultEndTime()],
            form: {
                settleStatus: '',
                developMemberName: '',
                deptCode: '',
                deptType: 3,
                search: '',
                nameSearch: '',
                memberSearch: '',
                goodsSearch: '',
                startTime: this.defaultStartTime(),
                endTime: this.defaultEndTime(),
                page: 1,
                limit: Util.Limit,
            },
            current: {
                typeIdx: 2,
                deptCode: '',
            },
            depts: [],
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/trade/cash/page',
            },
            recommenderList: [],
        };
    },
    filters: {
        settleStatus(settleStatus) {
            if (typeof settleStatus == 'undefined') {
                return '';
            }
            if (settleStatus == '0') {
                return '待结算';
            }
            if (settleStatus == '1') {
                return '已结算';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.depts = rst;
            this.form.deptCode = this.depts[0].code;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        // get90days() {
        //     let nowDate = new Date().getTime();
        //     let oldDate = nowDate - 90 * 24 * 60 * 1000  //近90天
        //     this.form.startTime = [oldDate.toString(),nowDate.toString()]
        //     console.log(this.form.startTime )
        // },
        // defaultStartTime() {
        //     const nowDate = new Date();
        //     nowDate.setMonth(nowDate.getMonth());
        //     return this.formatDate(nowDate);
        // },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        getRecommender() {
            ///member/listWhenSelect?deptCode=TDSDPT15675780645115F
            this.$http.get(`/member/listWhenSelect?deptCode=${this.form.deptCode}`).then((rsp) => {
                this.recommenderList = rsp.data.data;
            });
        },
        caclTradeFavour(row) {
            return (
                parseInt(row.totalFavour) +
                parseInt(row.promotionalFavour) +
                parseInt(row.memberFavour) +
                parseInt(row.peopleFavour) +
                parseInt(row.floor)
            );
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const vaildTime =
                !_this.timeAll || _this.timeAll.length === 0 || _this.timeAll[0] === '' || _this.timeAll[1] === '';
            if (vaildTime) {
                _this.$message.error('请选择开始时间和结束时间');
                return;
            }
            _this.form.startTime = _this.timeAll[0];
            _this.form.endTime = _this.timeAll[1];
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.sale.cash.detail', ['销售管理', '收银交易', '收银交易详情'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const vaildTime =
                !this.timeAll || this.timeAll.length === 0 || this.timeAll[0] === '' || this.timeAll[1] === '';
            if (vaildTime) {
                this.$message.error('请选择开始时间和结束时间');
                return;
            }
            UrlUtils.Export(this, '收银交易', '/trade/cash/export', this.form);
        },
    },
};
</script>

<style scoped>
.Cash .el-form-item {
    margin-bottom: 0;
}
</style>
